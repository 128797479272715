import { Config, getConnectorClient } from '@wagmi/core'
import { providers } from 'ethers'
import { useEffect, useState } from 'react'
import type { Account, Chain, Client, Transport } from 'viem'
import { useAccount } from 'wagmi'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client
  const network = {
    chainId: (chain as any).id,
    name: (chain as any).name,
    ensAddress: (chain as any).contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network)
  const signer = provider.getSigner((account as any).address)
  return signer
}

export function clientToWeb3(client: Client<Transport, Chain, Account>) {
  const { chain, transport } = client
  const network = {
    chainId: (chain as any).id,
    name: (chain as any).name,
    ensAddress: (chain as any).contracts?.ensRegistry?.address,
  }
  const provider = new providers.Web3Provider(transport, network)
  return provider
}

/** Action to convert a Viem Client to an ethers.js Signer. */
export async function getEthersSigner(
  config: Config,
  { chainId }: { chainId?: number } = {},
) {
  const client = await getConnectorClient(config, { chainId })
  return clientToSigner(client)
}

export async function getWeb3Provider(
  config: Config,
  { chainId }: { chainId?: number } = {},
) {
  const client = await getConnectorClient(config, { chainId })
  return clientToWeb3(client)
}

export function useWeb3Provider(config: Config) {
  const [provider, setProvider] = useState<providers.Web3Provider | any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { isConnected } = useAccount();

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted components

    const fetchProvider = async () => {
      try {
        setLoading(true);

        // Return a default provider if not connected
        if (!isConnected) {
          const defaultProvider = new providers.JsonRpcProvider("https://mainnet.base.org");
          if (isMounted) {
            setProvider(defaultProvider);
          }
          return;
        }

        // Fetch the Web3Provider when connected
        const web3Provider = await getWeb3Provider(config);
        if (isMounted) {
          setProvider(web3Provider);
        }
      } catch (err) {
        if (isMounted) {
          setError((err as Error).message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchProvider();

    return () => {
      isMounted = false; // Clean-up to avoid updating state after unmount
    };
  }, [config, isConnected]);

  return { provider, loading, error };
}



 
export const publicClient = createPublicClient({ 
  chain: base,
  transport: http("https://mainnet.base.org"),
  // multicall: {
  //   address: '0xcA11bde05977b3631167028862bE2a173976CA11',
  //   blockCreated: 14353601,
  // },
})