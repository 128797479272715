function setTheme(themeName) {
    localStorage.setItem('theme-island', themeName);
    document.documentElement.className = themeName;
}

function keepTheme() {
  // if (localStorage.getItem('theme-island')) {
    // if (localStorage.getItem('theme-island') === 'theme-island-1') {
    //   setTheme('theme-island-1');
    // }
    // if (localStorage.getItem('theme-island') === 'theme-island-2') {
    //   setTheme('theme-island-2')
    // }
    // if (localStorage.getItem('theme-island') === 'theme-island-3') {
    //   setTheme('theme-island-3')
    // }
    // if (localStorage.getItem('theme-island') === 'theme-island-4') {
    //   setTheme('theme-island-4')
    // }
  // } else {
    setTheme('theme-island-3')
  // }
}

module.exports = {
  setTheme,
  keepTheme
}