import React, { Suspense, lazy, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider as TP } from '@mui/material/styles';
import { ThemeProvider as TP1 } from 'styled-components';
import '@rainbow-me/rainbowkit/styles.css';

// import {
//   RainbowKitProvider,
// } from '@rainbow-me/rainbowkit';
// import { WagmiProvider } from 'wagmi';
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UseWalletProvider } from 'use-wallet';
import usePromptNetwork from './hooks/useNetworkPrompt';
import BanksProvider from './contexts/Banks';
import TombFinanceProvider from './contexts/TombFinanceProvider';
import ModalsProvider from './contexts/Modals';
import store from './state';
import theme from './theme';
import newTheme from './newTheme';
import config from './config';
import Updaters from './state/Updaters';
import Loader from './components/Loader';
import Popups from './components/Popups';
// import Regulations from './views/Regulations/Regulations';
import { keepTheme } from './utils/themeSwitchV2/Themes';
import { RefreshContextProvider } from './contexts/RefreshContext';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import {configWagmi} from './config';

const queryClient = new QueryClient();


const Home = lazy(() => import('./views/Home'));
const Cemetery = lazy(() => import('./views/Cemetery'));
const Masonry = lazy(() => import('./views/Masonry'));
// const Valley = lazy(() => import('./views/SwapBonds'));
const Valley = lazy(() => import('./views/Bonds'));
const SBS = lazy(() => import('./views/Sbs'));
const Regulations = lazy(() => import('./views/Regulations'));


const NoMatch = () => (
  <h3 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    URL Not Found. <a href="/">Go back home.</a>
  </h3>
);

const App: React.FC = () => {
  // Clear localStorage for mobile users
  if (typeof localStorage.version_app === 'undefined' || localStorage.version_app !== '1.1') {
    localStorage.clear();
    localStorage.setItem('connectorId', '');
    localStorage.setItem('version_app', '1.1');
  }

  useEffect(() => {
    keepTheme();
  })
  usePromptNetwork();

  // const hiddenTabs = true;

  return (
    <WagmiProvider config={configWagmi}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Providers>
            <Router>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/temple">
                    <Masonry />
                  </Route>
                  <Route path="/sarcophagus">
                    <Cemetery />
                  </Route>
                  <Route path="/valley">
                    <Valley />
                  </Route>
                  <Route path="/trade">
                    <SBS />
                  </Route>
                  <Route path="/stats">
                    <Regulations />
                  </Route>
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </Suspense>
            </Router>
          </Providers>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

const Providers: React.FC<{children: React.ReactNode
}> = ({ children }) => {
  return (
    <TP1 theme={theme}>
      <TP theme={newTheme}>
        <UseWalletProvider
          chainId={config.chainId}
          connectors={{
            walletconnect: { rpcUrl: config.defaultProvider },
            walletlink: {
              url: config.defaultProvider,
              appName: 'Obelisk Finance',
              appLogoUrl: 'https://i.ibb.co/nkSChnR/golden-obelisk-logo.png',
            },
          }}
        >
          <Provider store={store}>
            <Updaters />
            <RefreshContextProvider>
              <TombFinanceProvider>
                <ModalsProvider>
                  <BanksProvider>
                          <>
                            <Popups />
                            {children}
                          </>
                  </BanksProvider>
                </ModalsProvider>
              </TombFinanceProvider>
            </RefreshContextProvider>
          </Provider>
        </UseWalletProvider>
      </TP>
    </TP1>
  );
};

export default App;
