export const white = '#FFF';
export const black = '#000';

export const green = {
  500: '#00D110',
};

export const purple = {
  200: '#FEFE99',
  400: '#FECC33',
  700: '#CC9933',
};

export const red = {
  100: '#DAC27C',
  200: '#CEAC41',
  500: '#728cd7',
};

export const grey = {
  100: '#f5f5f5',
  200: '#eeeeee33',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#121212',
};

export const bg = '#000000';

export const teal = {
  200: '#64ffda',
};
