import React, { createContext, useEffect, useState } from 'react';
import TombFinance from '../../tomb-finance';
import config from '../../config';
import {configWagmi} from '../../config';
import { useWeb3Provider } from '../../wagmiAux';
import { useAccount } from 'wagmi';
import { getDefaultProvider } from 'ethers';

export interface TombFinanceContext {
  tombFinance?: TombFinance;
}

export const Context = createContext<TombFinanceContext>({ tombFinance: null });

export const TombFinanceProvider: React.FC<
{
  children: React.ReactNode;
}> = ({ children }) => {
  const { address: account } = useAccount();
  const provider = getDefaultProvider();

  const web3Provider = useWeb3Provider(configWagmi);
  const [tombFinance, setTombFinance] = useState<TombFinance>();

  useEffect(() => {
    if (!tombFinance) {
      const tomb = new TombFinance(config);
      if (account) {
        if (!web3Provider.provider) {
          tomb.unlockWallet(provider, account);
        } else {
          tomb.unlockWallet(web3Provider.provider, account);
        }
      }
      setTombFinance(tomb);
    } else if (account) {
      if (!web3Provider.provider) {
        tombFinance.unlockWallet(provider, account);
      } else {
        tombFinance.unlockWallet(web3Provider.provider, account);
      }
    }
  }, [account, tombFinance, provider, web3Provider]);

  return <Context.Provider value={{ tombFinance }}>{children}</Context.Provider>;
};
